<template>
  <div class="aw-card" @click="onCardClick($event)">
    <div class="aw-card__left">
      <label>{{ title }}</label>
      <p>{{ description }}</p>
      <span v-if="note">{{ note }}</span>
    </div>
    <div class="aw-card__right">
      <img :src="imgPath" :alt="title" width="32" height="55" />
      <LxpMenu
        :text="menuText"
        :items="menuItems"
        className="aw-card__menu"
        @select="onSelect"
        :noCaret="true"
        :isMenuTextHTML="true"
      />
    </div>
  </div>
</template>
<script>
import { LxpMenu } from "didactica";
import downloadCopy from "./mixins/downloadCopy";
export default {
  components: { LxpMenu },
  emits: ["copied"],
  mixins: [downloadCopy],
  props: {
    title: {
      type: String,
      default: ""
    },
    description: {
      type: String,
      default: ""
    },
    note: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      required: true
    },
    achievement: {
      type: Object,
      required: true
    }
  },
  computed: {
    menuText() {
      return `<img src="${require("@/assets/images/achievements/list/menu.svg")}">`;
    },
    imgPath() {
      let path;
      if (this.type === "course_participation_confirmation") {
        path =
          "https://whoalxppublicstorage.blob.core.windows.net/didactica/assets/images/awards/confirmation-of-participation.svg";
      } else if (this.type === "course_completion_award") {
        path =
          "https://whoalxppublicstorage.blob.core.windows.net/didactica/assets/images/awards/award-of-completion.svg";
      }
      return path;
    },
    courseId() {
      return this.achievement.course.key;
    },
    menuItems() {
      let arr = [
        {
          id: "about_course",
          label: "About",
          active: false
        },
        {
          id: "review",
          label: "Review course",
          active: false
        },
        {
          id: "share",
          label: "Share",
          active: false
        }
      ];
      if (this.achievement.download_url) {
        arr.splice(2, 0, {
          id: "download",
          label: "Download",
          active: false
        });
      }
      return arr;
    }
  },
  methods: {
    onSelect(s) {
      if (s.id === "about_course") {
        this.$router.push({ path: `/coursewares/${this.courseId}` });
      } else if (s.id === "download") {
        this.downloadAwards(this.achievement.download_url);
      } else if (s.id === "review") {
        this.$router.push({
          name: "Self Paced",
          params: { id: this.courseId }
        });
      } else if (s.id === "share") {
        this.shareAwards(this.achievement.share_url);
        this.$emit("copied");
      }
    },
    onCardClick(e) {
      if (!e.target.className.includes("dropdown")) {
        this.$router.push({
          name: "Achievements Detail",
          params: { id: this.achievement.uuid }
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.aw-card {
  box-shadow: 0px 1px 3px 1px rgba(33, 33, 33, 0.25);
  background: $brand-neutral-0;
  border-radius: 8px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 8px 16px 16px;
  min-height: 93px;
  cursor: pointer;
  .aw-card__left {
    text-align: left;
    label {
      @include label-large($brand-neutral-700, 500);
      margin-bottom: 4px;
    }
    p {
      @include subtitle-regular(400);
      color: $brand-neutral-900;
      margin-bottom: 4px;
    }
  }
  .aw-card__right {
    @include flex-horizontal-center;
    img {
      width: 50px;
      margin-top: -8px;
    }
  }
}
[dir="rtl"] {
  .aw-card {
    .aw-card__left {
      text-align: right;
    }
  }
}
</style>
<style lang="scss">
.aw-card__menu {
  .dropdown {
    &.show {
      .dropdown-toggle {
        background: $brand-primary-100 !important;
      }
    }
    .dropdown-toggle {
      background: transparent;
      padding: 0;
      height: 42px;
      width: 42px;
      border: none;
      @include flex-horizontal-center;
      &:hover,
      &:focus,
      &:active,
      &:focus-visible {
        border-radius: 100px;
        box-shadow: none;
        .lxp-menu--dropdown-button {
          padding: 0;
        }
      }
    }
  }
}
</style>
