import { render, staticRenderFns } from "./AchievementCard.vue?vue&type=template&id=1ed4abd6&scoped=true"
import script from "./AchievementCard.vue?vue&type=script&lang=js"
export * from "./AchievementCard.vue?vue&type=script&lang=js"
import style0 from "./AchievementCard.vue?vue&type=style&index=0&id=1ed4abd6&prod&lang=scss&scoped=true"
import style1 from "./AchievementCard.vue?vue&type=style&index=1&id=1ed4abd6&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ed4abd6",
  null
  
)

export default component.exports